import React from "react";
import { companyMethods } from "../../firebase/companyMethods";

import { Col, Button, Form, FormGroup, Label, Input, Row,  Container } from "reactstrap";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

class AddAdditionalTeamMember extends React.Component {
    state = {
        teamMemberID: "",
        fullName: "",
        bio: "",
        email:"",
        role:"",
        imageFiles:[],
        invalidInput: false
      };
    
      onFieldChange = (field, e) => {
        this.setState({
          [field]: e.target.value,
        });
      };
    
      onDrop = (files, allFiles) => {
          const newFiles = files["meta"];
          if(newFiles["status"] === "done"){
        this.setState((prevState) => {
          const newFiles = files["meta"];
        return {
              imageFiles: [...prevState.imageFiles, newFiles],
            }
          });
        } else if (newFiles["status"] === "removed"){
            this.setState((prevState) => {
                const removedFileState = this.state.imageFiles.filter(file => file["name"] !== newFiles["name"]);
                return {
                    imageFiles: removedFileState,
                  }
                });
        }
      }
    
      handleSubmit = (e) => {
        e.preventDefault();
        const randomString = Math.random().toString(36).slice(2);

        const teamMember = {
          teamMemberID: randomString,
          fullName: this.state.fullName,
          email: this.state.email,
          bio: this.state.bio,
          role: this.state.role,
          imageFiles: this.state.imageFiles
        };
        console.log(teamMember);
        const isNotEmpty = Object.values(teamMember).every(value => (value !== null && value !== ""));
        const imageFilesNotEmpty = Object.values(teamMember["imageFiles"]).length > 0;
        
        if(isNotEmpty && imageFilesNotEmpty){
            this.setState({
                invalidInput: false
            })
        companyMethods.addNewAdditionalTeamMember(teamMember);
        }else if(!isNotEmpty || !imageFilesNotEmpty){
            this.setState({
                invalidInput: true
            })
        };
      };
    
      render() {
          const invalidInput = this.state.invalidInput;
        return (
          <Container className="adminContainer">
            <h2>Add Team Member</h2>
       {invalidInput && (
           <div>All form fields must be complete to add team member.</div>
       )}
            <Form className="w-100" onSubmit={this.handleSubmit}>
              <Row form className="signup-form">
                <Col className="signup-form" md={6}>
                  <FormGroup>
                    <Label for="name">Full name</Label>
                    <Input
                      type="text"
                      name="fullName"
                      placeholder="Full Name"
                      onChange={this.onFieldChange.bind(null, "fullName")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="role">Role</Label>
                    <Input
                      type="text"
                      name="role"
                      placeholder="Manager"
                      onChange={this.onFieldChange.bind(null, "role")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      placeholder="email@email.com"
                      onChange={this.onFieldChange.bind(null, "email")}
                    />
                  </FormGroup>
    
                  <FormGroup>
                    <Label for="biography">Biography</Label>
                    <Input
                      type="textarea"
                      name="bio"
                      placeholder="About the team member"
                      onChange={this.onFieldChange.bind(null, "bio")}
                    />
                     </FormGroup>
                </Col>
                <Col className="signup-form" sm={12} md={6}>
    
                  <FormGroup>
                    <Label for="photo">Team Member Photo</Label>
                  </FormGroup>
                  <FormGroup>
                  <Dropzone 
                  maxFiles={1}
                  inputContent="Drop File Here"
                  styles={{
                    dropzone: { width: 550, height: 300 },
                    dropzoneActive: { borderColor: 'green' },
                  }}
                  onChangeStatus={this.onDrop}
    
                  />
                  </FormGroup>
                 <div className="submitButton" style={{textAlign: 'right'}}>
                  <Button size="lg">Add Team Member</Button>
                </div>
                </Col>

              </Row>
            </Form>
          </Container>
        );
      }
    }
export default AddAdditionalTeamMember;