import React from 'react';
import RichTextEditor from 'react-rte';
import { companyMethods } from '../firebase/companyMethods';
import { toolbarConfig } from './utils';
import { Container } from 'reactstrap';


import { Button } from 'reactstrap';



class EditCovid extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
    }

  componentDidMount() {
      companyMethods.getCovidContent().then(data => {
      if(data){
        const content =  data.content.toString();
      this.setState({
        value: RichTextEditor.createValueFromString(content, 'html'),
      })
    } else {
      this.setState({
        value: RichTextEditor.createEmptyValue(),
      })
    }
    });   
  }

  onChange = (value) => {
    this.setState({value});
  };

  handleSubmit= () => {
    const content = this.state.value.toString('html');
    companyMethods.updateCovid(content);
  }

  render () {
    return (
      <Container className="adminContainer">
      <h2>Edit Covid Page</h2>
      <RichTextEditor
        value={this.state.value}
        onChange={this.onChange}
        toolbarConfig={toolbarConfig}
        className="editorBackground"

      />
      <div className="updateAbout">
      <Button size="lg" className=""onClick={this.handleSubmit}>Update</Button>
      </div>
      </Container>
    );
  }
}
export default EditCovid;