import React from 'react';
import PropertyRow from './PropertyRow';

class Home extends React.Component {
    render() {
        return (
            <div className="home">
           <PropertyRow />
           </div>

        );
    }
}

export default Home;
