import React from "react";
import { companyMethods } from "../firebase/companyMethods";
import { Link } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Container,
} from "reactstrap";

import styles from "./styles/Properties.scss";
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";

class FindAPropertyMain extends React.Component {
  state = {
    value: [],
    allResults: [],
    min: "",
    max: "",
    state: "",
    city: "",
    zip: "",
    moveInDate: "",
    searchComplete: false,
    commercial: false,
    residential: false,
    submitting: true,
    hideRow: false,
    invalidSubmission: false,
  };

  componentDidMount() {
    companyMethods.getAllProperties().then((data) => {
      this.setState({
        value: data,
        allResults: data,
      });
    });
  }

  onFieldChange = async (field, e) => {
    await this.setState({
      [field]: e.target.value,
    });
    const { city, commercial, residential, min, max, moveInDate } = this.state;
    if (
      min.length > 1 &&
      max.length > 1 &&
      city.length > 1 &&
      moveInDate.length > 1 &&
      (commercial || residential)
    ) {
      this.setState({
        submitting: false,
      });
    }
  };

  onPropertyTypeChange = (field, e) => {
    if (e.target.value === "commercial") {
      this.setState({
        commercial: true,
        residential: false,
        submitting: false,
      });
    } else {
      this.setState({
        commercial: false,
        residential: true,
        submitting: false,
      });
    }
  };

  handleSubmit = () => {
    const {
      city,
      commercial,
      residential,
      min,
      max,
      moveInDate,
      allResults,
    } = this.state;
    if (
      city.length === 0 ||
      min.length === 0 ||
      max.length === 0 ||
      (moveInDate.length === 0 &&
        (commercial === false || residential === false))
    ) {
      this.setState({
        invalidSubmission: true,
      });
    } else {
      let filteredResults = [];
      filteredResults = allResults
        .filter((prop) => prop.city.toLowerCase() === city.toLowerCase())
        .filter(
          (prop) =>
            parseInt(prop.maxsq.replace(/,/g, ""), 10) >=
            parseInt(min.replace(/\,/g, ""), 10)
        )
        .filter(
          (prop) =>
            parseInt(prop.maxsq.replace(/\,/g, ""), 10) <=
            parseInt(max.replace(/\,/g, ""), 10)
        )
        .filter((prop) => prop.moveInDate === moveInDate)
        .filter((prop) => prop.residential === residential)
        .filter((prop) => prop.commercial === commercial);
      this.setState({
        value: filteredResults,
        searchComplete: true,
        hideRow: true,
        invalidSubmission: false,
      });
    }
  };

  onPropertyTypeChange = (field, e) => {
    if (e.target.value === "commercial") {
      this.setState({
        commercial: true,
        residential: false,
        submitting: false,
      });
    } else {
      this.setState({
        commercial: false,
        residential: true,
        submitting: false,
      });
    }
  };

  render() {
    const {
      value,
      searchComplete,
      allResults,
      invalidSubmission,
      hideRow,
    } = this.state;

    return (
      <>
        <section className="height">
          <Container fluid className="findYourPropertyMain">
            <div className="findPropertyMainTitle">
              Find a <strong>Property</strong>
            </div>
            <Row className="propertyMargin propertyFont">
              <Col className="col-lg-8 col-sm-10 mx-auto">
                {invalidSubmission && (
                  <div style={{ color: "red" }}>
                    You must fill out all required fields.
                  </div>
                )}
                <Row form className="main-form">
                  <Col md={2} sm={12} className="landscape">
                    <FormGroup tag="fieldset">
                      <Label for="name">Property Type</Label>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="propertyType"
                            value="commercial"
                            onChange={this.onPropertyTypeChange.bind(
                              null,
                              "commercial"
                            )}
                          />{" "}
                          Commercial
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="propertyType"
                            value="residential"
                            onChange={this.onPropertyTypeChange.bind(
                              null,
                              "residential"
                            )}
                          />{" "}
                          Residential
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={2} sm={12} className="landscape">
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        placeholder="e.g. Los Angeles"
                        onChange={this.onFieldChange.bind(null, "city")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2} sm={12} className="landscape">
                    <FormGroup>
                      <Label for="moveInDate">Move In Date</Label>
                      <Input
                        type="date"
                        name="moveInDate"
                        onChange={this.onFieldChange.bind(null, "moveInDate")}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2} sm={12} className="landscape">
                    <FormGroup>
                      <Label for="minSq">Min Sqft</Label>
                      <Input
                        name="min"
                        placeholder="e.g. 500"
                        onChange={this.onFieldChange.bind(null, "min")}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col md={2} className="landscape">
                    <FormGroup>
                      <Label for="maxSq">Max Sqft</Label>
                      <Input
                        name="max"
                        placeholder="e.g. 1,000"
                        onChange={this.onFieldChange.bind(null, "max")}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col
                    md={2}
                    className="landscape text-center"
                    style={{ margin: "auto 0" }}
                  >
                    <Button
                      style={{ padding: "0.7rem 1rem" }}
                      onClick={() => this.handleSubmit()}
                    >
                      Find Property
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {!hideRow && (
              <Container className="mainImagesCarousel">
                <hr className="solid" />
                <Row className="mainImages">
                  <Col sm="12" md="4">
                    <img className="img-fluid" src={image1} alt="" />
                  </Col>
                  <Col sm="12" md="4">
                    <img className="img-fluid" src={image2} alt="" />
                  </Col>
                  <Col sm="12" md="4">
                    <img className="img-fluid" src={image3} alt="" />
                  </Col>
                </Row>
              </Container>
            )}
        </section>
        <section className="section">
          {searchComplete && (
            <Container className="noPaddingMobile">
              <div style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
                <Row>
                  <Col className="col-lg-12 mx-auto">
                    {value.length === 0 && (
                      <>
                        <h2 className="noResults">
                          Sorry, we don't have any available properties with
                          your current search parameters. Browse our available
                          properties below.
                        </h2>

                        <Row className="propertyRow">
                          {allResults.map((val) => {
                            const propertyPath = val.propertyName
                              .split(" ")
                              .join("_");
                            return (
                              <Col
                                sm="12"
                                md="4"
                                className="propertyCard propertyMobile"
                                key={val.propertyID}
                              >
                                <Col md="12" className="propertyCardBackground">
                                  <div>
                                    <img
                                      className="img-fluid"
                                      src={val.photos[0]}
                                      alt=""
                                    />
                                    <div className="propertyTitle">
                                      {val.propertyName}
                                    </div>
                                    <div>{val.address}</div>
                                    <div>
                                      {val.city}, {val.state} {val.zip}
                                    </div>
                                    <div className="propertyRight">
                                      <Link
                                        to={{
                                          pathname:
                                            "properties/view-property/" +
                                            propertyPath +
                                            "/" +
                                            val.propertyID,
                                          propertyID: {
                                            name: val.propertyID,
                                            path: val.propertyName,
                                          },
                                        }}
                                      >
                                        <span className="btn btn-secondary smallerButton">
                                          {" "}
                                          View Property
                                        </span>
                                      </Link>
                                    </div>

                                    <br></br>
                                  </div>
                                </Col>
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="propertyRow">
                  {value.map((val) => {
                    const propertyPath = val.propertyName.split(" ").join("_");
                    return (
                      <Col
                        sm="12"
                        md="4"
                        className="propertyCard propertyMobile"
                        key={val.propertyID}
                      >
                        <Col md="12" className="propertyCardBackground">
                          <div>
                            <img
                              className="img-fluid"
                              src={val.photos[0]}
                              alt=""
                            />
                            <div className="propertyTitle">
                              {val.propertyName}
                            </div>
                            <div>{val.address}</div>
                            <div>
                              {val.city}, {val.state} {val.zip}
                            </div>
                            <div className="propertyRight">
                              <Link
                                to={{
                                  pathname:
                                    "properties/view-property/" +
                                    propertyPath +
                                    "/" +
                                    val.propertyID,
                                  propertyID: {
                                    name: val.propertyID,
                                    path: val.propertyName,
                                  },
                                }}
                              >
                                <span className="btn btn-secondary smallerButton">
                                  {" "}
                                  View Property
                                </span>
                              </Link>
                            </div>

                            <br></br>
                          </div>
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Container>
          )}
        </section>
      </>
    );
  }
}
export default FindAPropertyMain;
