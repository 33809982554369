import React from "react";
import { companyMethods } from "../firebase/companyMethods";
import {  Row, Col, Container } from "reactstrap";
import RichTextEditor from 'react-rte';


import '../components/styles/Properties.scss';
import '../views/about.scss';


class Team_User extends React.Component {
  state = {
    seniorTeam: [],
    additionalTeam: [],
    value: RichTextEditor.createEmptyValue()

  };

  componentDidMount() {
    companyMethods.getSeniorTeam().then((data) => {
      this.setState({
        seniorTeam: data
          });
    });
    companyMethods.getAdditionalTeam().then((data) => {
        this.setState({
          additionalTeam: data
        });
      });
      companyMethods.getTeamContent().then(data => {
        if(data){
          const newString =  data.content.toString();
        this.setState({
          value: RichTextEditor.createValueFromString(newString, 'html'),
        })
      } else {
        this.setState({
          value: RichTextEditor.createEmptyValue(),
        })
      }
      }); 
  }


  render() {
    const {
      seniorTeam,
      additionalTeam
    } = this.state;

    return (
      <Container className="userBackground">
        <h2>Our Team</h2>
        <RichTextEditor
        value={this.state.value}
        readOnly
        className='readOnly'

      />

        {seniorTeam.map((val) => {
          return (
            <Row className="propertyRow propertyRowAll justify-content-md-center" key={val.teamMemberID}>
              <Col
                sm="12"
                md="4"
                className="propertyMobile"
                key={val.teamMemberID}
              >
                <div style={{marginBottom: "10px"}}>
                  <img className="img-fluid" src={val.photos[0]} alt="" />
                </div>
              </Col>
              <Col sm="12" md="8">
                <div className="allPropertiesName">
                  <h2>{val.fullName}</h2>
                </div>
                <hr className="solid" />
                <div className="propertyDiv">

                </div>
                <div className="propertyAddress">
                  {val.role}
                </div>
                <div>{val.bio}</div>
              </Col>
            </Row>
          );
        })}
        {additionalTeam.map((val) => {
          return (
            <Row className="propertyRow propertyRowAll justify-content-md-center" key={val.teamMemberID}>
              <Col
                sm="12"
                md="4"
                className="propertyMobile"
                key={val.teamMemberID}
              >
                <div style={{marginBottom: "10px"}}>
                  <img className="img-fluid" src={val.photos[0]} alt="" />
                </div>
              </Col>
              <Col sm="12" md="8">
                <div className="allPropertiesName">
                  <h2>{val.fullName}</h2>
                </div>
                <hr className="solid" />
                <div className="propertyDiv">

                </div>
                <div className="propertyAddress">
                  {val.role}
                </div>
                <div>{val.bio}</div>
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }
}
export default Team_User;
