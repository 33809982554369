import React, { useContext } from 'react';
import { firebaseAuth } from './provider/AuthProvider';

import { Container }  from 'react-bootstrap';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from './static/Navigation';
import Home from './static/Home';
import AdminHome from './static/AdminHome';
import Header from './static/Header';
import Footer from './static/Footer';

import SignIn from './static/SignIn';

import AllProperties from './components/AllProperties';
import EditAbout from './components/EditAbout';
import EditCovid from './components/EditCovid';
import EditCareers from './components/EditCareers';
import EditContact from './components/EditContact';
import EditTenants from './components/EditTenants';
import EditTeam from './components/EditTeam';
import AddProperty from './components/Properties/addProperty';
import EditProperty from './components/Properties/editProperty';
import AllUsers from './components/UserManagement/AllUsers';
import MyAccount from './components/UserManagement/MyAcocunt';
import AddSeniorTeam from './components/Team/AddSeniorTeamMember';
import AddAdditionalTeam from './components/Team/AddAdditionalTeamMember';
import AllTeamMembers from './components/Team/AllTeamMembers';
import EditSeniorTeam from './components/Team/EditSeniorTeam';
import EditAdditionalTeam from './components/Team/EditAdditionalTeam';
import FindAProperty from './components/FindAProperty';



import AllProperties_User from './views/AllProperties_User';
import UserView_Property from './views/ViewProperty_User';
import Contact_User from './views/Contact_User';
import Careers_User from './views/Careers_User';
import Team_User from './views/Team_User';
import About_User from './views/About_User';
import Tenants_User from './views/Tenants_User';


export default function App() {

  const isAdmin = window.location.pathname.toString().includes("admin");
  const { isLoggedIn } = useContext(firebaseAuth);

  return (
   <Router>
      <div>
          {isAdmin && (<Navigation isAdmin={isAdmin} isLoggedIn={isLoggedIn}/>)}
           {!isAdmin && <Header isAdmin={isAdmin} isLoggedIn={isLoggedIn} /> }
          <Container fluid className="main" style={{padding:0}} >
          <Switch>
              <Route exact path="/" component={Home} />


              <Route path="/all-properties" component={AllProperties_User} />
              <Route path="/properties/find-a-property" component={FindAProperty} />

              <Route path="/properties/view-property" render={rProps => <UserView_Property {...rProps} />} />

              <Route path="/about" component={About_User} />
              <Route path="/tenants" component={Tenants_User} />
              <Route path="/contact" component={Contact_User} />
              <Route path="/careers" component={Careers_User} />
              <Route path="/team" component={Team_User} />
                  </Switch>
          </Container>  
          <Footer />

      </div>
    </Router>
  );
}
