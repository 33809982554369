import React from "react";
import { companyMethods } from "../firebase/companyMethods";
import { Link } from "react-router-dom";
import { Col, Row, Container, Input, InputGroup } from "reactstrap";

class AllProperties_User extends React.Component {
  state = {
    value: [],
    allResults: [],
    filterdZip: false,
    filteredState: false,
    filteredCity: false,
    filteredMinMax: false,
    filteredMoveInDate: false,
    min: "",
    max: "",
    state: "",
    city: "",
    zip: "",
    moveInDate: "",
    loading: true
  };

  componentDidMount() {
    companyMethods.getAllProperties().then((data) => {
      this.setState({
        value: data,
        allResults: data,
        loading: false
      });
    });
  }

  onSelectedChange = (e) => {
    const { allResults } = this.state;
    if (e.target.value === "City") {
      this.setState({
        filteredCity: true,
        filteredZip: false,
        filteredMinMax: false,
        filteredState: false,
        filteredMoveInDate: false,
      });
    } else if (e.target.value === "State") {
      this.setState({
        filteredCity: false,
        filteredZip: false,
        filteredMinMax: false,
        filteredState: true,
        filteredMoveInDate: false,
      });
    } else if (e.target.value === "Zip") {
      this.setState({
        filteredCity: false,
        filteredZip: true,
        filteredMinMax: false,
        filteredState: false,
        filteredMoveInDate: false,
      });
    } else if (e.target.value === "Sq") {
      this.setState({
        filteredCity: false,
        filteredZip: false,
        filteredMinMax: true,
        filteredState: false,
        filteredMoveInDate: false,
      });
    } else if (e.target.value === "MoveInDate") {
      this.setState({
        filteredCity: false,
        filteredZip: false,
        filteredMinMax: false,
        filteredState: false,
        filteredMoveInDate: true,
      });
    } else {
      this.setState({
        filteredCity: false,
        filteredZip: false,
        filteredMinMax: false,
        filteredState: false,
        filteredMoveInDate: false,
        zip: "",
        city: "",
        state: "",
        moveInDate: "",
        min: "",
        max: "",
        value: allResults
      });
    }
  };

  onFieldChange = async (field, e) => {
    await this.setState({
      [field]: e.target.value,
    });
    const {
      city,
      state,
      min,
      max,
      zip,
      moveInDate,
      allResults,
    } = this.state;
    let filteredResults = [];
    if (city.length > 1) {
      filteredResults = allResults.filter(
        (prop) => prop.city.toLowerCase() === city.toLowerCase()
      );
      console.log(filteredResults);
      this.setState({
        value: filteredResults,
      });
    } else if (state.length > 1) {
      filteredResults = allResults.filter(
        (prop) => prop.state.toLowerCase() === state.toLowerCase()
      );
      this.setState({
        value: filteredResults,
      });
    } else if (zip.length > 1) {
      filteredResults = allResults.filter((prop) => prop.zip === zip);
      this.setState({
        value: filteredResults,
      });
    } else if (min.length > 1 && max.length > 1) {
      filteredResults = allResults
        .filter((prop) => parseInt(prop.maxsq) >= min)
        .filter((prop) => parseInt(prop.maxsq) <= max);
      this.setState({
        value: filteredResults,
      });
    } else if (moveInDate.length > 1) {
      filteredResults = allResults.filter(
        (prop) => prop.moveInDate === moveInDate
      );
      this.setState({
        value: filteredResults,
      });
    } else {
      this.setState({
        value: allResults,
      });
    }
  };

  render() {
    const {
      value,
      filteredZip,
      filteredMoveInDate,
      filteredCity,
      filteredMinMax,
      filteredState,
      loading
    } = this.state;

    return (
      <Container className="userBackground">
        <h2 className="paddingH2">All Properties</h2>
        <Row className="allProperties">
          <Col sm="12" md={{ size: 5, offset: 7 }}>
            <InputGroup>
              <span className="filterBy">Filter By: </span>
              <Input
                type="select"
                onChange={this.onSelectedChange}
                className="filterSelect"
              >
                <option value="All" defaultValue>
                  All Properties
                </option>
                <option value="City">City</option>
                <option value="Sq">Min & Max Sqft</option>
                <option value="MoveInDate">Move In Date</option>
                <option value="State">State</option>
                <option value="Zip">Zip</option>
              </Input>
              <div className="mobileFilter">
                {filteredCity && (
                  <Input
                    name="city"
                    placeholder="e.g. Los Angeles"
                    onChange={this.onFieldChange.bind(null, "city")}
                  ></Input>
                )}
                {filteredState && (
                  <Input
                    name="state"
                    placeholder="e.g. CA"
                    onChange={this.onFieldChange.bind(null, "state")}
                  ></Input>
                )}
                {filteredMoveInDate && (
                  <Input
                    name="moveInDate"
                    type="date"
                    placeholder="e.g. 1/1/20"
                    onChange={this.onFieldChange.bind(null, "moveInDate")}
                  ></Input>
                )}
                {filteredZip && (
                  <Input
                    name="zip"
                    placeholder="e.g. 90026"
                    onChange={this.onFieldChange.bind(null, "zip")}
                  ></Input>
                )}
                {filteredMinMax && (
                  <>
                    <Input
                      name="min"
                      placeholder="e.g. 400"
                      onChange={this.onFieldChange.bind(null, "min")}
                      className="filterSelect form-control"
                    ></Input>
                    <span className="filterMiddle">-</span>
                    <Input
                      name="max"
                      placeholder="e.g. 2,000"
                      onChange={this.onFieldChange.bind(null, "max")}
                      className="filterSelectLeft form-control"
                    ></Input>
                  </>
                )}
              </div>
            </InputGroup>
          </Col>
        </Row>
        <Row>
              <Col className="col-lg-12 mx-auto">

            {value.length === 0 && !loading &&(
                <>
                <h2 className="noResults">Sorry, we don't have any available properties with your current filter parameters.</h2>
                </>
                )}
                </Col>
                </Row>

        {value.map((val) => {
          const propertyPath = val.propertyName.split(" ").join("_");
          return (
            <Row className="propertyRow propertyRowAll justify-content-md-center" key={val.propertyID}>
              <Col
                sm="12"
                md="4"
                className="propertyMobile"
                key={val.propertyID}
              >
                <div style={{marginBottom: "10px"}}>
                  <img className="img-fluid" src={val.photos[0]} alt="" />
                </div>
              </Col>
              <Col sm="12" md="8">
                <div className="allPropertiesName">
                  <h2>{val.propertyName}</h2>
                </div>
                <hr className="solid" />
                <div className="propertyDiv">
                  <span className="propertyType">
                    {val.commercial ? "Commercial" : "Residential"}
                  </span>
                </div>
                <div className="propertyAddress">
                  {val.address}, {val.city}, {val.state}, {val.zip}{" "}
                </div>
                <div>{val.description}</div>
                <div className="propertyRight">
                  <Link
                    to={{
                      pathname:
                        "properties/view-property/" +
                        propertyPath +
                        "/" +
                        val.propertyID,
                      propertyID: {
                        name: val.propertyID,
                        path: val.propertyName,
                      },
                    }}
                    
                  >
                    <span className="btn btn-secondary smallerButton">View Property</span>
                  </Link>
                </div>
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }
}
export default AllProperties_User;
