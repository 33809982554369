import React from 'react';
import { Col, Row } from 'react-bootstrap';

class AllUsers extends React.Component {
    state= {
        allUsers: {}
    };

    componentWillMount(){
            // List batch of users, 1000 at a time.
            // admin.auth().listUsers(1000)
            //   .then(function(listUsersResult) {
            //     listUsersResult.users.forEach(function(userRecord) {
            //       console.log('user', userRecord.toJSON());
            //     });

            //   })
            //   .catch(function(error) {
            //     console.log('Error listing users:', error);
            //   }); 
    }
    render() {
        return (
            <Row>
              <Col>team page</Col>
            </Row>
        );
    }
}

export default AllUsers;