import React from "react";
import "./styles/Navigation.scss";

import AdminNavigation from './AdminNavigation';
import UserNavigation from './UserNavigation';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav
} from 'reactstrap';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const isAdmin = this.props.isAdmin;
    const isLoggedIn = this.props.isLoggedIn;

    return (
      <div>
        <Navbar className="navbar-background" light expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav className="m-auto" navbar>
              {isAdmin && isLoggedIn ? (
               <AdminNavigation/>
                 
              ) : !isAdmin ? (
               <UserNavigation/>
              ) : ""}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
