import React from "react";
import FindAPropertyMain from '../components/FindAPropertyMain';



class PropertyRow extends React.Component {
 
  render() {
 
    return (
      <>
      <FindAPropertyMain />
      </>
    );
  }
}
export default PropertyRow;
