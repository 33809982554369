import React from "react";
import { companyMethods } from "../../firebase/companyMethods";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Container
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

class EditSeniorTeam extends React.Component {

  state = {
    fullName: "",
    email: "",
    role: "",
    bio: "",
    imageFiles: [],
    teamMemberID: "",
    uploadedPhotos: [],
    invalidInput: ""
  };

 
  componentDidMount() {
    if (this.props.location && this.props.location.teamMemberID) {
      console.log(this.props.location);
      const teamMemberID = this.props.location.teamMemberID.name;
      this.setState({
        teamMemberID: teamMemberID,
      });
      this.getInfo(teamMemberID);
    } else {
      const pageURL = window.location.href;
      const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);  
      const teamMemberIDPath = lastURLSegment.replace(/_/g, ' '); 
      this.setState({
        teamMemberID: teamMemberIDPath
      });
      this.getInfo(teamMemberIDPath);
    }
  }

  getInfo = (teamMemberID) => {
    companyMethods
    .getSeniorTeamMember(teamMemberID)
    .then((data) => {
      this.setState({
        teamMemberID: data["teamMemberID"],
        fullName: data["fullName"],
        email: data["email"],
        bio: data["bio"],
        role: data["role"],
        imageFiles: [],
        uploadedPhotos: data["photos"]
      });
    });
  }

  onFieldChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  onDrop = (files, allFiles) => {
    const newFiles = files["meta"];
    if (newFiles["status"] === "done") {
      this.setState((prevState) => {
        const newFiles = files["meta"];
        return {
          imageFiles: [...prevState.imageFiles, newFiles],
        };
      });
      console.log(this.state.imageFiles);
    } else if (newFiles["status"] === "removed") {
      this.setState((prevState) => {
        const removedFileState = this.state.imageFiles.filter(
          (file) => file["name"] !== newFiles["name"]
        );
        return {
          imageFiles: removedFileState,
        };
      });
    }
  };

  deletePhoto = (photo) => {
      console.log(photo);
      const { uploadedPhotos } = this.state;
      const filterOutPhoto = uploadedPhotos.filter(pic => pic !== photo);
    this.setState({
        uploadedPhotos: filterOutPhoto
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.uploadedPhotos)
    console.log(this.state.imageFiles);
    const teamMemberID = this.state.teamMemberID;
    const teamMember = {
        teamMemberID: this.state.teamMemberID,
        fullName: this.state.fullName,
        email: this.state.email,
        bio: this.state.bio,
        role: this.state.role,
        imageFiles: this.state.imageFiles,
        uploadedPhotos: this.state.uploadedPhotos
      };

      // const isNotEmpty = Object.values(teamMember).every(value => (value !== null && value !== ""));
      const imageFilesNotEmpty = Object.values(teamMember["imageFiles"]).length > 0;
      const uploadedPhotos = Object.values(teamMember["uploadedPhotos"]).length > 0;

      if(uploadedPhotos && imageFilesNotEmpty){
        this.setState({
            invalidInput: false
        })
        companyMethods.updateSeniorTeamMember(teamMember, teamMemberID);

      } else {
        this.setState({
            invalidInput: true
        })
      }

  };

  render() {
      const { invalidInput } = this.state;
    return (
      <Container className="adminContainer">
        <h2>Edit Senior Team Member</h2>
        {invalidInput && (
            <div>
            Please upload a photo.
            </div>
        )}

        <Form className="w-100" onSubmit={this.handleSubmit}>
          <Row form className="signup-form">
            <Col className="signup-form" md={6}>
              <FormGroup>
                <Label for="name">Full Name</Label>
                <Input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  onChange={this.onFieldChange.bind(null, "fullName")}
                  value={this.state.fullName}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="text"
                  name="email"
                  placeholder="admin@email.com"
                  onChange={this.onFieldChange.bind(null, "email")}
                  value={this.state.email}

                />
              </FormGroup>
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="text"
                  name="role"
                  placeholder="Role"
                  onChange={this.onFieldChange.bind(null, "role")}
                  value={this.state.role}

                />
              </FormGroup>
                  <FormGroup>
                    <Label for="bio">Bio</Label>
                    <Input
                      type="textarea"
                      name="bio"
                      placeholder="About the team member"
                      onChange={this.onFieldChange.bind(null, "bio")}
                      value={this.state.bio}

                    />
                  </FormGroup>
            
            </Col>
            <Col className="signup-form" sm={12} md={6}>
             
              <FormGroup>
                <Label for="photo">Team Member Photo</Label>
              </FormGroup>
              {this.state.uploadedPhotos.map((photo) => {
                 return (
                <div style={{paddingBottom: '20px'}} key={photo}>
                 <img src={photo} width="100" height="100" style={{marginRight: "15px", marginLeft:"15px"}} alt=""/>
                 <Button className="btn btn-secondary smallerButton" onClick={() => { this.deletePhoto(photo) }}>delete</Button>
                 </div>)
              })}
              {this.state.uploadedPhotos.length ===  0 &&(
              <>
              <FormGroup>
                <Label for="photo">Add Team Member Photo</Label>
              </FormGroup>
              <Dropzone
                multiple
                inputContent="Drop Files Here"
                styles={{
                  dropzone: { width: 450, height: 200 },
                  dropzoneActive: { borderColor: "green" },
                }}
                onChangeStatus={this.onDrop}

              />
              </>
              )}
            </Col>
            <div className="get-on-the-list" style={{textAlign: "right"}}>
              <Button className="btn btn-secondary smallerButton">Update Team Member</Button>
            </div>
          </Row>
        </Form>
      </Container>
    );
  }
}
export default EditSeniorTeam;
