import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import firebase from 'firebase'

class AdminHome extends React.Component {
    state = {
        user: "",
        currentUser: ""
    }
    componentWillMount() {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            this.setState({ user: user.email, currentUser: user });
          }
        });
      }
    render() {
        return (
            <Container className="adminContainer">
            <div style={{minHeight: "400px"}}>
             <h2>Kanon Ventures Admin Portal</h2>
            <Row>
              <Col>Welcome to the Admin Portal, {this.state.user}! </Col>
            </Row>
            </div>
            </Container>
        );
    }
}

export default AdminHome;
