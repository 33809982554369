import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { companyMethods } from '../firebase/companyMethods';

import RichTextEditor from 'react-rte';

import './about.scss';


class Careers_User extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
  }

  componentDidMount() {
    companyMethods.getCareersContent().then(data => {
    if(data){
      const newString =  data.content.toString();
    this.setState({
      value: RichTextEditor.createValueFromString(newString, 'html'),
    })
  } else {
    this.setState({
      value: RichTextEditor.createEmptyValue(),
    })
  }
  });   
}
  
    render() {
        return (
            <Container className="userBackground">
            <h2>Careers</h2>
            <RichTextEditor
             value={this.state.value}
             readOnly
             className='readOnly'
      />
          </Container>
        );
    }
}

export default Careers_User;
