import React from "react";
import { companyMethods } from "../firebase/companyMethods";
import { Col, Row, Container } from "reactstrap";
import { Carousel } from "react-bootstrap";
import "./viewProperty.scss";

class User_ViewProperty extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    propertyName: "",
    link: "",
    address: "",
    description: "",
    leasingName: "",
    leasingEmail: "",
    city: "",
    state: "",
    zip: "",
    vacancies: "",
    maxsq: "",
    moveInDate: "",
    parkingSpaces: "",
    imageFiles: [],
    propertyID: "",
    uploadedPhotos: [],
    commercial: "",
    residential: "",
  };

  componentDidMount() {
    console.log(this.props.location);
    if (this.props.location && this.props.location.propertyID) {
      const propertyID = this.props.location.propertyID.name;
      this.setState({
        propertyID: propertyID,
      });
      this.getInfo(propertyID);
    } else {
      const pageURL = window.location.href;
      const lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);
      const propertyIDPath = lastURLSegment.replace(/_/g, " ");
      console.log(propertyIDPath);
      this.setState({
        propertyID: propertyIDPath,
      });
      this.getInfo(propertyIDPath);
    }
  }

  getInfo = (propertyID) => {
    companyMethods.getProperty(propertyID).then((data) => {
      this.setState({
        propertyID: data["propertyID"],
        propertyName: data["propertyName"],
        link: data["link"],
        address: data["address"],
        description: data["description"],
        leasingEmail: data["leasingEmail"],
        leasingName: data["leasingName"],
        city: data["city"],
        state: data["state"],
        zip: data["zip"],
        vacancies: data["vacancies"],
        maxsq: data["maxsq"],
        moveInDate: data["moveInDate"],
        imageFiles: [],
        parkingSpaces: data["parkingSpaces"],
        uploadedPhotos: data["photos"],
        commercial: data["commercial"],
        residential: data["residential"],
      });
    });
  };

  render() {
    const {
      propertyName,
      parkingSpaces,
      uploadedPhotos,
      moveInDate,
      maxsq,
      vacancies,
      zip,
      state,
      city,
      leasingEmail,
      leasingName,
      address,
      description,
      commercial,
      residential,
      link,
    } = this.state;

    return (
      <Container className="userBackground">
        <Row style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
          <Col sm={12} md={6} xs={{ span: 12, order: 2 }}>
            <div className="propertyName">
              <h2>{propertyName}</h2>
            </div>
            <hr className="solid" />
            <div className="propertyDiv">
              <span className="propertyType">
                {commercial ? "Commercial" : "Residential"}
              </span>
            </div>

            <div className="propertyAddress">
              {address}, {city}, {state}, {zip}{" "}
            </div>
            <div className="propertyDescription">{description}</div>
            <Row>
              <Col className="text-center">
                <span style={{ fontWeight: "600" }}>Parking Spaces</span>
                <Col>{parkingSpaces}</Col>
              </Col>
              <Col className="text-center">
                <span style={{ fontWeight: "600" }}>Total Sqft</span>
                <Col>{maxsq}</Col>
              </Col>
              <Col className="text-center">
                <span style={{ fontWeight: "600" }}>Vacancies</span>
                <Col>{vacancies}</Col>
              </Col>
            </Row>

            <div></div>
            <div className="propertyContact">
              <span style={{ fontWeight: "600" }}>Leasing Agent </span>
              <div>{leasingName}</div>
              <div>{leasingEmail}</div>
            </div>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <a href={link} className="btn btn-secondary smallerButton" rel="noopener noreferrer" target="_blank">
                Property Website
              </a>
            </div>
          </Col>
          <Col sm={12} md={6} xs={{ span: 12, order: 1 }}>
            <div className="propertyImage">
            <Carousel>
                <Carousel.Item interval={800}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[0]}
                    alt=""
                  />
                </Carousel.Item>
                {uploadedPhotos[1] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[1]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                {uploadedPhotos[2] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[2]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                {uploadedPhotos[3] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[3]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                {uploadedPhotos[4] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[4]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                {uploadedPhotos[5] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[5]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                {uploadedPhotos[6] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[6]}
                    alt=""
                  />
                </Carousel.Item> : ""}
                    {uploadedPhotos[7] ?  <Carousel.Item interval={700}>
                  <img
                    className="img-fluid"
                    src={uploadedPhotos[7]}
                    alt=""
                  />
                </Carousel.Item> : ""}
          
            </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default User_ViewProperty;
