import React from "react";
import { companyMethods } from "../firebase/companyMethods";
import { Link } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Container
} from "reactstrap";

class FindAProperty extends React.Component {
  state = {
    value: [],
    allResults: [],
    min: "",
    max: "",
    state: "",
    city: "",
    zip: "",
    moveInDate: "",
    searchComplete: false,
    commercial: false,
    residential: false,
    submitting: true,
    invalidSubmission: false


  };

  componentDidMount() {
    companyMethods.getAllProperties().then((data) => {
      this.setState({
        value: data,
        allResults: data,
      });
    });
    
  }


  onFieldChange = async (field, e) => {
    await this.setState({
      [field]: e.target.value,
    });
    const {
        city,
        commercial,
        residential,
        min,
        max,
        moveInDate,
      } = this.state;
      if(min.length > 1 && max.length > 1 && city.length > 1  && moveInDate.length > 1 && (commercial || residential)){
        this.setState({
            submitting: false
        })
      }
  };


  onPropertyTypeChange = (field, e) => {
    if(e.target.value === "commercial"){
        this.setState({
            commercial: true,
            residential: false,
            submitting: false
          });

    } else {
        this.setState({
            commercial: false,
            residential: true,
            submitting: false
          });
    }
};


handleSubmit = () => {
  const {
      city,
      commercial,
      residential,
      min,
      max,
      moveInDate,
      allResults
        } = this.state;
    if(city.length === 0 || min.length === 0 || max.length === 0 || moveInDate.length === 0  && ( commercial === false || residential === false)){
      this.setState({
        invalidSubmission: true
      })
    }else {

 
    let filteredResults = [];

      filteredResults = allResults.filter(
        (prop) => prop.city.toLowerCase() === city.toLowerCase()
      ).filter((prop) => parseInt(prop.maxsq.replace(/,/g, ''), 10) >=  parseInt(min.replace(/\,/g,''), 10))
      .filter((prop) => parseInt(prop.maxsq.replace(/\,/g,''), 10) <=  parseInt(max.replace(/\,/g,''), 10)).filter(
          (prop) => prop.moveInDate === moveInDate
        ).filter((prop => prop.residential === residential))
        .filter((prop => prop.commercial === commercial));
      this.setState({
        value: filteredResults,
        searchComplete: true,
        invalidSubmission: false
      });
    }
}
  onPropertyTypeChange = (field, e) => {
    if (e.target.value === "commercial") {
      this.setState({
        commercial: true,
        residential: false,
        submitting: false,
      });
    } else {
      this.setState({
        commercial: false,
        residential: true,
        submitting: false
      });
    }
  };

  render() {
    const {
      value,
      searchComplete,
      invalidSubmission,
      allResults
    } = this.state;

    return (
      <Container className="userBackground">
      <h2 className="findPropertyMainTitle">Find a Property</h2>
        <Row>
        {invalidSubmission && (
                <div style={{color: "red"}}>You must fill out all required fields.</div>
              )}
          <Col sm="12" md="12">
            <Row form>
              <Col md={2}>
                <FormGroup tag="fieldset">
                  <Label for="name">Property Type</Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="propertyType"
                        value="commercial"
                        onChange={this.onPropertyTypeChange.bind(
                          null,
                          "commercial"
                        )}
                      />{" "}
                      Commercial
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="propertyType"
                        value="residential"
                        onChange={this.onPropertyTypeChange.bind(
                          null,
                          "residential"
                        )}
                      />{" "}
                      Residential
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="city">City</Label>
                  <Input
                    type="text"
                    name="city"
                    placeholder="e.g. Los Angeles"
                    onChange={this.onFieldChange.bind(null, "city")}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="city">Move In Date</Label>
                  <Input
                    type="date"
                    name="moveInDate"
                    onChange={this.onFieldChange.bind(null, "moveInDate")}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="city">Min Sqft</Label>
                  <Input
                    name="min"
                    placeholder="e.g. 500"
                    onChange={this.onFieldChange.bind(null, "min")}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="city">Max Sqft</Label>
                  <Input
                    name="max"
                    placeholder="e.g. 1,000"
                    onChange={this.onFieldChange.bind(null, "max")}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={2} className="text-center" style={{ margin: "auto 0" }}>
                <Button className="btn btn-secondary" style={{ padding: "0.5rem" }} onClick={() => this.handleSubmit()}>Find Property</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {searchComplete && (
          <div style={{paddingTop: "3rem"}}>
            <h5>Property Results</h5>
            <Row>
              <Col className="col-lg-12 mx-auto">

            {value.length === 0 && (
                <>
                <h2 className="noResults">Sorry, we don't have any available properties with your current search parameters. Browse our available properties below.</h2>
              
                     <Row className="propertyRow">
                     {allResults.map((val) => {
                       const propertyPath = val.propertyName.split(" ").join("_");
                       return (
                         <Col
                           sm="12"
                           md="4"
                           className="propertyCard propertyMobile"
                           key={val.propertyID}
                         >
                         <Col md="12" className="propertyCardBackground">
                           <div>
                             <img className="img-fluid" src={val.photos[0]} alt="" />
                             <div className="propertyTitle">{val.propertyName}</div>
                             <div>{val.address}</div>
                             <div>
                               {val.city}, {val.state} {val.zip}
                             </div>
                             <div className="propertyRight">
                               <Link
                                 to={{
                                   pathname:
                                     "properties/view-property/" +
                                     propertyPath +
                                     "/" +
                                     val.propertyID,
                                   propertyID: {
                                     name: val.propertyID,
                                     path: val.propertyName,
                                   },
                                 }}
                               >
                                <span className="btn btn-secondary smallerButton"> View Property</span>
                               </Link>
                             </div>
       
                             <br></br>
                           </div>
                           </Col>
                         </Col>
                       );
                     })}
                   </Row>
                   </>
            )}
            </Col>
            </Row>
            <Row className="propertyRow justify-content-md-center">
              {value.map((val) => {
                const propertyPath = val.propertyName.split(" ").join("_");
                return (
                  <Col
                  sm="12"
                  md="4"
                  className="propertyCard propertyMobile"
                  key={val.propertyID}
                >
                <Col md="12" className="propertyCardBackground">
                  <div>
                    <img className="img-fluid" src={val.photos[0]} alt=""/>
                    <div className="propertyTitle">{val.propertyName}</div>
                    <div>{val.address}</div>
                    <div>
                      {val.city}, {val.state} {val.zip}
                    </div>
                    <div className="propertyRight">
                      <Link
                        to={{
                          pathname:
                            "properties/view-property/" +
                            propertyPath +
                            "/" +
                            val.propertyID,
                          propertyID: {
                            name: val.propertyID,
                            path: val.propertyName,
                          },
                        }}
                      >
                      <span className="btn btn-secondary smallerButton"> View Property</span>
                      </Link>
                    </div>

                    <br></br>
                  </div>
                  </Col>
                </Col>
                );
              })}
            </Row>
          </div>
        )}
      </Container>
    );
  }
}
export default FindAProperty;
