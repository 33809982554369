import React from "react";
import { companyMethods } from "../../firebase/companyMethods";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Container
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import "../styles/EditProperty.scss";

class EditProperty extends React.Component {
 
  state = {
    propertyName: "",
    link: "",
    address: "",
    description: "",
    leasingName: "",
    leasingEmail: "",
    city: "",
    state: "",
    zip: "",
    vacancies: "",
    maxsq: "",
    moveInDate: "",
    parkingSpaces: "",
    imageFiles: [],
    propertyID: "",
    uploadedPhotos: [],
    commercial: false,
    residential: false,
  };

 
  componentDidMount() {
    console.log(this.props.location);
    if (this.props.location && this.props.location.propertyID) {
      const propertyID = this.props.location.propertyID.name;
      this.setState({
        propertyID: propertyID,
      });
      this.getInfo(propertyID);
    } else {
      const pageURL = window.location.href;
      const lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);  
      const propertyIDPath = lastURLSegment.replace(/_/g, ' '); 
      console.log(propertyIDPath);
      this.setState({
        propertyID: propertyIDPath
      });
      this.getInfo(propertyIDPath);
    }
  }

  getInfo = (propertyID) => {
    companyMethods
    .getProperty(propertyID)
    .then((data) => {
      this.setState({
        propertyID: data["propertyID"],
        propertyName: data["propertyName"],
        link: data["link"],
        address: data["address"],
        description: data["description"],
        leasingEmail: data["leasingEmail"],
        leasingName: data["leasingName"],
        city: data["city"],
        state: data["state"],
        zip: data["zip"],
        vacancies: data["vacancies"],
        maxsq: data["maxsq"],
        moveInDate: data["moveInDate"],
        imageFiles: [],
        parkingSpaces: data["parkingSpaces"],
        uploadedPhotos: data["photos"],
        commercial: data["commercial"],
        residential: data["residential"]
      });
    });
  }

  onFieldChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  onPropertyTypeChange = (field, e) => {
    if(e.target.value === "commercial"){
        this.setState({
            commercial: true,
            residential: false
          });

    } else {
        this.setState({
            commercial: false,
            residential: true
          });
    }
};
  onDrop = (files, allFiles) => {
    const newFiles = files["meta"];
    if (newFiles["status"] === "done") {
      this.setState((prevState) => {
        const newFiles = files["meta"];
        return {
          imageFiles: [...prevState.imageFiles, newFiles],
        };
      });
      console.log(this.state.imageFiles);
    } else if (newFiles["status"] === "removed") {
      this.setState((prevState) => {
        const removedFileState = this.state.imageFiles.filter(
          (file) => file["name"] !== newFiles["name"]
        );
        return {
          imageFiles: removedFileState,
        };
      });
    }
  };

  deletePhoto = (photo) => {
      const propertyID = this.state.propertyID;
      const allPhotos = this.state.uploadedPhotos;
      companyMethods.deletePhoto(photo, propertyID, allPhotos);
  }

  handleSubmit = (e) => {

    e.preventDefault();
    console.log(this.state.residental);
    const propertyID = this.state.propertyID;
    const property = {
      propertyName: this.state.propertyName,
      link: this.state.link,
      address: this.state.address,
      description: this.state.description,
      leasingEmail: this.state.leasingEmail,
      leasingName: this.state.leasingName,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      vacancies: this.state.vacancies,
      parkingSpaces: this.state.parkingSpaces,
      maxsq: this.state.maxsq,
      moveInDate: this.state.moveInDate,
      imageFiles: this.state.imageFiles,
      uploadedPhotos: this.state.uploadedPhotos,
      commercial: this.state.commercial,
      residential: this.state.residential
    };
    companyMethods.updateProperty(property, propertyID);
  };

  render() {
    return (
        <Container className="adminContainer">
        <h2>Edit Property</h2>

        <Form className="w-100" onSubmit={this.handleSubmit}>
          <Row form className="signup-form">
            <Col className="signup-form" md={6}>
              <FormGroup>
                <Label for="name">Property Name</Label>
                <Input
                  type="text"
                  name="propertyName"
                  placeholder="Property Name"
                  onChange={this.onFieldChange.bind(null, "propertyName")}
                  value={this.state.propertyName}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <Label for="name">Property Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="propertyType"
                      value="commercial"
                      checked={this.state.commercial}
                      onChange={this.onPropertyTypeChange.bind(null, "commercial")}
                    />{" "}
                    Commercial
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="propertyType"
                      checked={this.state.residential}
                      value="residential"
                      onChange={this.onPropertyTypeChange.bind(null, "residential")}
                    />{" "}
                    Residential
                  </Label>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Label for="link">Property Website Link</Label>
                <Input
                  type="text"
                  name="link"
                  placeholder="http://www.example.com"
                  onChange={this.onFieldChange.bind(null, "link")}
                  value={this.state.link}

                />
              </FormGroup>
              <FormGroup>
                <Label for="address">Property Address</Label>
                <Input
                  type="text"
                  name="address"
                  placeholder="Property Address"
                  onChange={this.onFieldChange.bind(null, "address")}
                  value={this.state.address}

                />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="city">City</Label>
                    <Input
                      type="text"
                      name="city"
                      placeholder="City"
                      onChange={this.onFieldChange.bind(null, "city")}
                      value={this.state.city}

                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      type="text"
                      name="state"
                      placeholder="state"
                      onChange={this.onFieldChange.bind(null, "state")}
                      value={this.state.state}

                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="zip">Zip</Label>
                    <Input
                      type="text"
                      name="zip"
                      placeholder="zip"
                      onChange={this.onFieldChange.bind(null, "zip")}
                      value={this.state.zip}

                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="email">Leasing Contact Information</Label>
                <Input
                  type="text"
                  name="leasingName"
                  placeholder="Leasing Contact Name"
                  onChange={this.onFieldChange.bind(null, "leasingName")}
                  value={this.state.leasingName}

                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="leasingEmail"
                  placeholder="Leasing Contact Email"
                  onChange={this.onFieldChange.bind(null, "leasingEmail")}
                  value={this.state.leasingEmail}

                />
              </FormGroup>
            </Col>
            <Col className="signup-form" sm={12} md={6}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxsq">Total Sqft</Label>
                    <Input
                      type="text"
                      name="maxsq"
                      placeholder="Total Sqft"
                      onChange={this.onFieldChange.bind(null, "maxsq")}
                      value={this.state.maxsq}

                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={5}>
                  <FormGroup>
                    <Label for="moveInDate">Move In Date</Label>
                    <Input
                      type="date"
                      name="MoveInDate"
                      placeholder="Move In Date"
                      onChange={this.onFieldChange.bind(null, "moveInDate")}
                      value={this.state.moveInDate}

                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="parkingSpaces">Parking Spaces</Label>
                    <Input
                      type="text"
                      name="parkingSpaces"
                      placeholder="Parking Spaces"
                      onChange={this.onFieldChange.bind(null, "parkingSpaces")}
                      value={this.state.parkingSpaces}

                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="Vacancies">Vacancies</Label>
                    <Input
                      type="text"
                      name="vacancies"
                      placeholder="Vacancies"
                      onChange={this.onFieldChange.bind(null, "vacancies")}
                      value={this.state.vacancies}

                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="Description">Property Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      placeholder="Description"
                      onChange={this.onFieldChange.bind(null, "description")}
                      value={this.state.description}

                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="photo">Property Photos</Label>
              </FormGroup>
              {this.state.uploadedPhotos.map((photo) => {
                 return (
                <div style={{paddingBottom: '40px'}} key={photo}>
                 <img src={photo} width="150" height="100" alt=""/>
                 <Button size="sm" className="btnMargin" onClick={() => { this.deletePhoto(photo) }}>delete</Button>
                 </div>)
              })}
              <FormGroup>
                <Label for="photo">Add Additional Property Photos</Label>
              </FormGroup>
              <Dropzone
                multiple
                inputContent="Drop Files Here"
                styles={{
                  dropzone: { width: 450, height: 200 },
                  dropzoneActive: { borderColor: "green" },
                }}
                onChangeStatus={this.onDrop}
              />
            </Col>
            <div className="updateProperty">
              <Button size="lg">Update Property</Button>
            </div>
          </Row>
        </Form>
      </Container>
    );
  }
}
export default EditProperty;
