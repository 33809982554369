import React from "react";
import {
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Container
} from "reactstrap";
import firebase from "firebase";

class MyAccount extends React.Component {
  state = {
    user: "",
    currentUser: {},
    newPassword: "",
    password: "",
    confirmPassword: "",
    wrongPassword: false,
    error: "",
    success: ""
  };

  componentWillMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: user.email, currentUser: user });
      }
    });
  }
  onFieldChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };
  updatePassword = () => {
    //   e.preventDefault();    

    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    const currentUser = this.state.user;
    console.log(currentUser);
    if (password === confirmPassword && password.length > 6) {
      this.setState({
        wrongPassword: false,
      });
      firebase
        .auth()
        .currentUser.updatePassword(password)
        .then( () => {
            this.setState({
                success: "Password Updated",
                wrongPassword: false,
                error: ""
            })
          // Update successful.
        })
        .catch((error) => {
            this.setState({
                error: error.message,
                success: "",
                wrongPassword: false
              });
        });
    }  else {
      this.setState({
        wrongPassword: true,
        error: "",
        success:""
      });
    }
  };
  render() {
    const user = this.state.user;
    const wrongPassword = this.state.wrongPassword;
    const error = this.state.error;
    const success = this.state.success;
    return (
      <Container className="adminContainer">       
       <h2>My Account</h2>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="email">Email Address</Label>

              <Input type="email" readOnly value={user}></Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="email">Update Password</Label>
              {wrongPassword && <div>Passwords do not match.</div>}
              {error && <div> {error}</div>}
              {success && <div> {success}</div>}


              <Input
                type="password"
                placeholder="Password"
                onChange={this.onFieldChange.bind(null, "password")}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                placeholder="Confirm Password"
                onChange={this.onFieldChange.bind(null, "confirmPassword")}
              />
            </FormGroup>
            <Button onClick={() => this.updatePassword()}>
              Update Password
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MyAccount;
