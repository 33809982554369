import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { companyMethods } from '../firebase/companyMethods';
import Main from '../images/Main.jpg';

import RichTextEditor from 'react-rte';

import './about.scss';


class About_User extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
  }

  componentDidMount() {
    companyMethods.getAboutContent().then(data => {
    if(data){
      const newString =  data.content.toString();
    this.setState({
      value: RichTextEditor.createValueFromString(newString, 'html'),
    })
  } else {
    this.setState({
      value: RichTextEditor.createEmptyValue(),
    })
  }
  });   
}
  
    render() {
        return (
            <Container className="userBackground">
              <div style={{paddingBottom: "2rem"}}>
            <h2 style={{paddingBottom: "2rem"}}>About Kanon Ventures</h2>
            {/* <Row className="text-center mb-2">
              <Col sm={12} md={12}>
              <img src={Main} className="img-fluid" />
              </Col>
            </Row> */}
            <RichTextEditor
             value={this.state.value}
             readOnly
             className='readOnly'
      />
            </div>
          </Container>
        );
    }
}

export default About_User;
