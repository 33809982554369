import React from 'react';
import { Col, Row } from 'react-bootstrap';

class Footer extends React.Component {
    render() {
        return (
                <Row>
                  <Col md={12}>
              {/* <div className="footer text">
                  Copyright 2020
              </div> */}
              </Col>
                </Row>
        );
    }
}

export default Footer;
