import React from 'react';
import RichTextEditor from 'react-rte';
import { companyMethods } from '../firebase/companyMethods';
import { Container } from 'reactstrap';

import { toolbarConfig } from './utils';
import { Button } from 'reactstrap';

import styles from './styles/EditAbout.scss';


class EditTeam  extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
    }

  componentDidMount() {
      companyMethods.getTeamContent().then(data => {
      if(data){
        const newString =  data.content.toString();
      this.setState({
        value: RichTextEditor.createValueFromString(newString, 'html'),
      })
    } else {
      this.setState({
        value: RichTextEditor.createEmptyValue(),
      })
    }
    });   
  }

  onChange = (value) => {
    this.setState({value});
  };

  handleSubmit= () => {
    const content = this.state.value.toString('html');
    companyMethods.updateTeam(content);
  }

  render () {
    return (
      <Container className="adminContainer">
      <h2>Edit Team Overview </h2>
      <RichTextEditor
        value={this.state.value}
        onChange={this.onChange}
        toolbarConfig={toolbarConfig}
        className="editorBackground"
      />
       <div className="updateAbout">
      <Button size="lg" className=""onClick={this.handleSubmit}>Submit</Button>
      </div>
      </Container>
    );
  }
}
export default EditTeam;