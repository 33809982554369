import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { companyMethods } from '../firebase/companyMethods';

import RichTextEditor from 'react-rte';

import './about.scss';
import Emaint from '../images/emaint.png';
import RentCafe from '../images/rentcafe.png'


class Tenants_User extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
  }

  componentDidMount() {
    companyMethods.getTenantsContent().then(data => {
    if(data){
      const newString =  data.content.toString();
    this.setState({
      value: RichTextEditor.createValueFromString(newString, 'html'),
    })
  } else {
    this.setState({
      value: RichTextEditor.createEmptyValue(),
    })
  }
  });   
}
  
    render() {
        return (
            <Container className="userBackground">
            <h2>Tenants</h2>
            <RichTextEditor
             value={this.state.value}
             readOnly
             className='readOnly'
      />
      <Row className="tenants justify-content-md-center">
        <Col md={4} style={{textAlign: "center"}}>
        <img src={Emaint} alt="" className="img-fluid" />
        <p style={{textAlign: "center"}}>
          <a href="https://x31.emaint.com/?_ga=2.14066295.1784104907.1603847066-563478846.1603847066" target="_blank">Emaint</a>
        </p>
        </Col>
        <Col md={4} style={{textAlign: "center"}}>
        <img src={RentCafe} alt="" className="img-fluid" />
        <p style={{textAlign: "center"}}>
          <a href="https://www.rentcafe.com/residentservices/apartmentsforrent/userlogin.aspx" target="_blank">Rent Cafe</a>
        </p>
        </Col>
      </Row>
          </Container>
        );
    }
}

export default Tenants_User;
