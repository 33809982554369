
import React, {useContext, useState} from 'react';
import { firebaseAuth } from '../provider/AuthProvider';
import { NavLink } from "react-router-dom";
import "./styles/Navigation.scss";

import { NavItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";


export default function AdminNavigation () {
    const { handleSignout } = useContext(firebaseAuth);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownTeamOpen, setDropdownTeamOpen] = useState(false);
    const [dropdownAllPropertiesOpen, setDropdownAllPropertiesOpen] = useState(false);


    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggleTeam = () => setDropdownTeamOpen(!dropdownTeamOpen);
    const toggleAllProperties = () => setDropdownAllPropertiesOpen(!dropdownAllPropertiesOpen);



    const activeNavStyle = {
        fontWeight: "bold",
        fontStyle: "italic"
        };

    return (
        <>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/admin" activeStyle={activeNavStyle}>
                Home
              </NavLink>
            </span>
          </NavItem>
          <Dropdown nav isOpen={dropdownAllPropertiesOpen} toggle={toggleAllProperties}>
          <DropdownToggle nav caret>
            Properties
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/all-properties" activeStyle={activeNavStyle}>
                All Properties
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/properties/add-property" activeStyle={activeNavStyle}>
                Add Property
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
          <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle nav caret>
            Edit About
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/edit-covid" activeStyle={activeNavStyle}>
                Edit Covid
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/edit-about" activeStyle={activeNavStyle}>
                Edit History
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown nav isOpen={dropdownTeamOpen} toggle={toggleTeam}>
          <DropdownToggle nav caret>
            Edit Team
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/team/edit-team" activeStyle={activeNavStyle}>
                Edit Team Overview
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/team/all-team-members" activeStyle={activeNavStyle}>
                All Team Members
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/team/add-senior-team" activeStyle={activeNavStyle}>
                Add Senior Team Member
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/admin/team/add-additional-team" activeStyle={activeNavStyle}>
                Add Team Member
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/admin/edit-tenants" activeStyle={activeNavStyle}>
                Edit Tenants
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/admin/edit-careers" activeStyle={activeNavStyle}>
                Edit Careers
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/admin/edit-contact" activeStyle={activeNavStyle}>
                Edit Contact
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/admin/users/my-account" activeStyle={activeNavStyle} onClick={handleSignout}>
                My Account
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/admin/logout" activeStyle={activeNavStyle} onClick={handleSignout}>
                Logout
              </NavLink>
            </span>
          </NavItem>
        </>
      );
    
}
