import React from 'react';
import { Col, Row, Container, Input, Label, InputGroup} from 'reactstrap';
import { companyMethods } from '../firebase/companyMethods';

import RichTextEditor from 'react-rte';

import './about.scss';


class Contact_User extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue()
  }

  componentDidMount() {
    companyMethods.getContactContent().then(data => {
    if(data){
      const newString =  data.content.toString();
    this.setState({
      value: RichTextEditor.createValueFromString(newString, 'html'),
    })
  } else {
    this.setState({
      value: RichTextEditor.createEmptyValue(),
    })
  }
  });   
}
  
    render() {
        return (
          <Container className="userBackground">
            <h2>Contact</h2>
            <RichTextEditor
              value={this.state.value}
              readOnly
              className="readOnly"
            />
            <Row form action="https://formspree.io/f/xgepzwro" method="POST">
              <Col md={12}>
                <Col style={{paddingBottom:"2rem"}}>
                <Label>Name <span style={{color:"red"}}>*</span></Label>
               <Input type="text" name="name" />
               </Col>
               <Col style={{paddingBottom:"2rem"}}>
               <Label>Email <span style={{color:"red"}}>*</span></Label>
                <Input type="email" name="_replyto" />
                </Col>
                <Col>
                <Label>Message <span style={{color:"red"}}>*</span></Label>
                <Input type="textarea" name="message" />
                </Col>
                <Col md={4} className="text-center" style={{paddingTop: "2rem"}}>

                <Input type="submit" value="Send" className="btn btn-secondary smallerButton" />
                </Col>
              </Col>
            </Row>
          </Container>
        );
    }
}

export default Contact_User;

