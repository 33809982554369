import React from "react";
import RichTextEditor from "react-rte";
import { companyMethods } from "../firebase/companyMethods";

import { toolbarConfig } from "./utils";
import { Button, Container } from "reactstrap";

import styles from "./styles/EditAbout.scss";

class EditContact extends React.Component {
  state = {
    value: RichTextEditor.createEmptyValue(),
  };

  componentDidMount() {
    companyMethods.getContactContent().then((data) => {
      if (data) {
        const newString = data.content.toString();
        this.setState({
          value: RichTextEditor.createValueFromString(newString, "html"),
        });
      } else {
        this.setState({
          value: RichTextEditor.createEmptyValue(),
        });
      }
    });
  }

  onChange = (value) => {
    this.setState({ value });
  };

  handleSubmit = () => {
    const content = this.state.value.toString("html");
    companyMethods.updateContact(content);
  };

  render() {
    return (
      <Container className="adminContainer">
        <h2>Edit Contact Page</h2>
        <RichTextEditor
          value={this.state.value}
          onChange={this.onChange}
          toolbarConfig={toolbarConfig}
          className="editorBackground"
        />
        <div className="updateAbout">
          <Button size="lg" className="" onClick={this.handleSubmit}>
            Update
          </Button>
        </div>{" "}
      </Container>
    );
  }
}
export default EditContact;
