import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles/Navigation.scss";


import { NavItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";


export default function UserNavigation () {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownAllPropertiesOpen, setDropdownAllPropertiesOpen] = useState(false);


    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggleAllProperties = () => setDropdownAllPropertiesOpen(!dropdownAllPropertiesOpen);



    const activeNavStyle = {
        fontWeight: "bold",
        fontStyle: "italic"
        };

    return (
        <>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/" activeStyle={activeNavStyle}>
                Home
              </NavLink>
            </span>
          </NavItem>
          <Dropdown nav isOpen={dropdownAllPropertiesOpen} toggle={toggleAllProperties}>
          <DropdownToggle nav caret>
            Our Properties
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/all-properties" activeStyle={activeNavStyle}>
                All Properties
              </NavLink>
            </span></DropdownItem>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/properties/find-a-property" activeStyle={activeNavStyle}>
                Find a Property
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
          <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle nav caret>
            The Company
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/about" activeStyle={activeNavStyle}>
                Our History
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/team" activeStyle={activeNavStyle}>
                Our Team
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/careers" activeStyle={activeNavStyle}>
                Careers
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/tenants" activeStyle={activeNavStyle}>
                Tenants
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/contact" activeStyle={activeNavStyle}>
                Contact Us
              </NavLink>
            </span>
          </NavItem>
        </>
      );
    
}
