import React from "react";
import Navigation from '../static/Navigation';


import "./styles/Header.scss";

class Header extends React.Component {
  render() {

    const { isLoggedIn, isAdmin} = this.props;

    return (
     <header className="masthead">
     <Navigation isAdmin={isAdmin} isLoggedIn={isLoggedIn}/>
      <div className="mastheadMargin container d-flex h-50 align-items-center">
      <div className="mx-auto text-center">
                    <h1 className="mx-auto my-0">kanon <strong>ventures</strong></h1>
                    <h2 className="managedBy">managed by elite property management</h2>
                </div>

      </div>
      </header>
    );
  }
}

export default Header;
